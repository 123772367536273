import React from 'react';
import nikeLogo from '../Components/Images/brand1.png';
import adidasLogo from '../Components/Images/brand2.png';
import pumaLogo from '../Components/Images/brand3.png';
import filaLogo from '../Components/Images/brand4.png';
import abclogo from '../Components/Images/brand5.png';

import './Home.css';

const brands = [
    { id: 1, name: 'Nike', logo: nikeLogo },
    { id: 2, name: 'Adidas', logo: adidasLogo },
    { id: 3, name: 'Puma', logo: pumaLogo },
    { id: 4, name: 'abc', logo: abclogo },
    { id: 4, name: 'Fila', logo: filaLogo },

];

function Brands() {
    return (
        <div className="flash-news-container">
            <div className="flash-news">
                {/* Original logos */}
                {brands.map((item) => (
                    <div key={item.id} className="news-item">
                        <img src={item.logo} alt={`${item.name} Logo`} className="brand-logo" />
                    </div>
                ))}
                {/* Duplicate logos */}
                {brands.map((item) => (
                    <div key={`duplicate-${item.id}`} className="news-item">
                        <img src={item.logo} alt={`${item.name} Logo`} className="brand-logo" />
                    </div>
                ))}

{brands.map((item) => (
                    <div key={`duplicate-${item.id}`} className="news-item">
                        <img src={item.logo} alt={`${item.name} Logo`} className="brand-logo" />
                    </div>
                ))}
                {brands.map((item) => (
                    <div key={`duplicate-${item.id}`} className="news-item">
                        <img src={item.logo} alt={`${item.name} Logo`} className="brand-logo" />
                    </div>
                ))}{brands.map((item) => (
                  <div key={`duplicate-${item.id}`} className="news-item">
                      <img src={item.logo} alt={`${item.name} Logo`} className="brand-logo" />
                  </div>
              ))}
            </div>
        </div>
    );
}

export default Brands;
