import React from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap';


function Companies({ closeCompanies,CompaniesRef }) {
    return (
      <div className='Companies-main' ref={CompaniesRef}>
      <div className='Companies'>
      <div className='close-icon-container' onClick={closeCompanies} >
     </div> 
     <br/>
     <div className='Companies-text'>
      <Row>
        <Col xs={12} md={6} className='Companies-text'>
        <h1 className='bold'>Anzeer Kalapurackal’s  Companies

</h1>
<span className='bold'>DAK International:</span><h6>A building materials distribution firm that serves clients across Kerala and beyond, providing a wide range of high-quality products essential for construction and building projects. DAK International is known for its reliable supply chain and commitment to delivering top-tier materials for infrastructure development.
</h6>
<h6>
<span className='bold'> DAK Engineering:</span>  &nbsp;This company is focused on engineering services, specializing in quartz and granite fabrication, countertop installation, and structural fabrication in steel, aluminum, and glass. DAK Engineering also offers expert services in blasting and painting, along with advanced epoxy and PU flooring solutions.

</h6>
<h6>
<span className='bold'>Firana Foods:</span> 
&nbsp;Under the Firana Foods umbrella, Anzeer Kalapurackal established Firana Cookies, a brand known for its premium baked goods. Expanding beyond cookies, Firana Foods aims to bring a variety of delicious and nutritious food products to consumers, combining quality with innovation in the food industry.


</h6>
        </Col>

        <Col xs={12} md={6}>
        <h6>
<span className='bold'> Luxcraft Media:</span> 
&nbsp;A full-service media and marketing agency, Luxcraft Media helps businesses thrive in the digital landscape by providing expert social media management, content creation, and advertising solutions. The company is dedicated to elevating brands through creative and engaging content that resonates with their audience.


</h6>
<h6>
<span className='bold'>  Thach Han Corporation:</span>  &nbsp;
Specializing in the manufacturing of high-end home furniture and decor, including the well-known Thach Han Sofas and Thach Hans Decores. This firm focuses on crafting stylish, comfortable, and durable home furnishings, blending traditional craftsmanship with modern design aesthetics.


</h6>


<h6>
<span className='bold'> PESLA International:</span>  &nbsp;
PESLA International: A leading distributor of Grohe Kitchen Sinks across Kerala, PESLA International also offers a range of high-quality home improvement products such as Z-Living, Zea Mirrors, and wardrobes. This company is dedicated to enhancing home environments with stylish and functional products.


</h6>
<h6>
<span className='bold'> Firana Kampany:</span>  &nbsp;
While still under development, Firana Kampany is poised to be a key player in the food and lifestyle sector, with an emphasis on providing high-quality consumer goods. With this new venture, Anzeer seeks to expand his presence in the food industry and beyond, bringing innovation and excellence to everyday products.


</h6>
        </Col>
      </Row>
     </div>
   
      </div>
      </div>
    
  
    )
  }

export default Companies
