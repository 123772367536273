import React from 'react'
import './Sub.css';
import { Col, Row, Form, Button } from 'react-bootstrap';

import sign from '../Components/Images/sign.png';

function About({ closeAbout,aboutRef }) {
  return (
    <div className='About-main' ref={aboutRef}>
        <div className='About'>
        <div className='close-icon-container' onClick={closeAbout} >
       </div>   
       
       <br/>
       <div className='about-text'>
        <Row>
            <Col xs={12} md={4}>
            <br/>

            <h5 className='bold'>INTRODUCTION</h5>
            <p>Anzeer Kalappurackal is a visionary entrepreneur with a proven track record of building and managing multiple successful companies across a wide range of industries. His business portfolio spans sectors such as construction materials, engineering, food production, media, and lifestyle, demonstrating his ability to identify opportunities and execute with precision. Anzeer’s strategic mindset and passion for innovation have positioned him as a prominent business leader in markets across Kerala, the Middle East, and beyond.

</p>
            <br/><br/>
            
            <h4 className='bold'>VALUES AND VISION</h4>

<h6>Anzeer Kalappurackal’s business ethos is rooted in delivering exceptional value through innovation, quality, and customer satisfaction. He believes in fostering strong, lasting relationships with clients and stakeholders, underpinned by a commitment to integrity and excellence in every endeavor. His companies prioritize sustainability, community impact, and a culture of continuous improvement, all of which reflect Anzeer’s dedication to making a positive difference in society and the industries he serves.

</h6> <br/><br/>
         
            <p><img src={sign}/></p>
          
            </Col>
            <Col xs={12} md={4}>
            <br/>


            <h4 className='bold'>LOOKING AHEAD</h4>
            <h6>As he continues to grow his entrepreneurial footprint, Anzeer remains focused on exploring new markets, scaling operations, and staying ahead of industry trends. With an eye on further diversifying his portfolio, his vision is to ensure that each company under his leadership remains a leader in its respective sector. By embracing innovation and maintaining a customer-centric approach, Anzeer is committed to driving sustainable growth and expanding his influence on both local and global stages.

            </h6>
            <h6 className='bold'>Anzeer Kalapurackal’s Companies

</h6>
<span className='bold'>DAK International:</span><h6>A leader in the distribution of building materials, DAK International serves clients across Kerala, the Middle East, and other regions. Known for its reliable supply chain and commitment to quality, the company provides top-tier construction products for infrastructure and development projects.

</h6>
<h6>
<span className='bold'> DAK Engineering:</span>  &nbsp;Specializing in quartz and granite fabrication, structural steel, aluminum, and glass work, DAK Engineering also offers high-end services such as blasting, painting, and advanced epoxy and PU flooring solutions. It stands out for delivering cutting-edge engineering services that meet the highest industry standards

</h6>

            </Col>
            <Col xs={12} md={4}>
            <br/>
          
            <h6>
<span className='bold'>Firana Foods:</span> 
&nbsp;Firana Foods, known for its premium baked goods, including the popular Firana Cookies, is expanding into broader food production. The brand is committed to delivering nutritious, high-quality products while driving innovation within the food industry.



</h6>
<h6>
<span className='bold'> Luxcraft Media:</span> 
&nbsp;Luxcraft Media is a full-service media agency that helps businesses thrive in the digital landscape through expert social media management, content creation, and advertising strategies. It is dedicated to enhancing brands through impactful and creative content.


</h6>
<h6>
<span className='bold'>  Thach Han Corporation:</span>  &nbsp;
Thach Han Corporation is a renowned manufacturer of premium home furniture and decor, known for blending modern design with traditional craftsmanship. The company’s stylish and durable home furnishings, including Thach Han Sofas, have made it a leader in the home decor sector.


</h6>


<h6>
<span className='bold'> PESLA International:</span>  &nbsp;
A leading distributor of home improvement products, PESLA International offers premium brands such as Grohe Kitchen Sinks, Z-Living, and Zea Mirrors. The company is dedicated to enhancing home environments with products that combine style and functionality.


</h6>
<h6>
<span className='bold'> Firana Kampany:</span>  &nbsp;
Poised to become a key player in the consumer goods sector, Firana Kampany focuses on delivering high-quality food and lifestyle products. With this venture, Anzeer aims to further expand his presence in the food industry and beyond.

</h6>
            </Col>
     

        </Row>

       </div>
      
        </div>
     
        {/* Add your close icon or button here */}
     
    </div>
  )
}

export default About
