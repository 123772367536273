import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Home.css';
import CloseIcon from '@mui/icons-material/Close';

function Topbar({ onClose ,scrollToSection}) { // Accept onClose as a prop
  const [isOpen, setIsOpen] = useState(true); // Initially, the navbar is open

  const closeNavbar = () => {
    setIsOpen(false); // Close the navbar
    onClose(); // Call onClose to inform Home component
  };

  return (
    <>
      {isOpen && ( // Render the Top div only if isOpen is true
        <div className='Top'>
          <Navbar expand="lg">
            <Container>
            <div className="close-icon-wrapper">
                <CloseIcon style={{ color: "white", fontSize: "40px" }} onClick={closeNavbar} />
              </div>
              <Navbar.Collapse in={isOpen} id="basic-navbar-nav">
                <Nav className="me-auto justify-content-center w-100">
                <Nav.Link onClick={() => scrollToSection('home')} className="text-white extra-light-text">HOME</Nav.Link>
                  <Nav.Link onClick={() => scrollToSection('about')} className="text-white extra-light-text">ABOUT</Nav.Link>
                  <Nav.Link onClick={() => scrollToSection('gallery')} className="text-white extra-light-text">GALLERY</Nav.Link>
                  <Nav.Link onClick={() => scrollToSection('companies')} className="text-white extra-light-text">COMPANIES</Nav.Link>
                  <Nav.Link onClick={() => scrollToSection('contact')} className="text-white extra-light-text">CONTACT</Nav.Link>
                  <Nav.Link onClick={() => scrollToSection('testimonials')} className="text-white extra-light-text">TESTIMONIALS</Nav.Link>
                </Nav>
              </Navbar.Collapse>

            </Container>
          </Navbar>
        </div>
      )}
    </>
  );
}

export default Topbar;
