import React from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap';

function Contact({ closeContact,ContactRef }) {
    return (
      <div className='Contact-main' ref={ContactRef}>
      <div className='Contact'>
      <div className='close-icon-container' onClick={closeContact} >
     </div>  
        <br/>
        <div className='Contact-text'>

            <Row>
                <Col xs={12} md={4}>
                <br/>

                <h3>
                AGENCY

                </h3>
                <h6>Kalapurackal Enterprises
                Representatives:
                </h6>
                <p>Anzeer Kalapurackal
                </p>
                <p>Website: www.kalapurackalenterprises.com
                </p>
                <p>Phone: +1 310 123 4567
                </p>
                <p>Fax: +1 310 987 6543
                </p>
                <a style={{textDecoration:"none",color:"white"}} href="mailto:info@kalapurackalenterprises.com">
                    info@kalapurackalenterprises.com
                  </a>
                <p>ADDRESS:
                    <br/>
                    Kalapurackal Plaza, 1234 Business Center Drive
Los Angeles, CA 90010
USA


                </p>
                </Col>
                <Col xs={12} md={4}>
                <br/>

                <h3>MANAGEMENT
                </h3>
                <h6>Kalapurackal Consulting
                Representative:
                </h6>
                <p>Anzeer Kalapurackal
                </p>
                <a style={{textDecoration:"none",color:"white"}} href="mailto:anzeerkalapurackal@gmail.com">
                   Email: anzeerkalapurackal@gmail.com
                  </a>
             
                </Col>
                <Col xs={12} md={4}>
                <br/>

                <h2>BUSINESS ENQUIRIES
                </h2>

                <a style={{textDecoration:"none",color:"white"}} href="mailto:anseer@dakinternational.com">
                   Email: anseer@dakinternational.com
                  </a>
                </Col>
            </Row>
            </div>
      </div>
      </div>
    
  
    )
  }

export default Contact
