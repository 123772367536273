import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function MasonryImageList() {
  // Use Material-UI's useMediaQuery to set the number of columns based on screen size
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:960px)');
  const sortedItemData = itemData.sort((a, b) => a.title.localeCompare(b.title));

  // Set number of columns based on screen size
  const cols = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;
  const gap = isSmallScreen ? 8 : isMediumScreen ? 60 : 80;
  const padding = isSmallScreen ? '6px' : isMediumScreen ? '20px' : '70px';

  return (
    <Box sx={{ width: '100%', height: 'auto', padding: padding }}>
      {/* Use ImageList with masonry variant */}
      <ImageList variant="masonry" cols={cols} gap={gap}>
        {sortedItemData.map((item) => (
          <ImageListItem 
            key={item.img} 
            sx={{ 
              height: 'auto',  // Ensures that height is handled dynamically
            }}
          >
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              style={{
                height: item.height,  // Custom height from item data
                width: '100%',        // Ensure image spans full width
                objectFit: 'cover',   // Maintain aspect ratio and avoid distortion
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: 'Imgs/gk24.jpg',
    title: 'Image 1 ',
    height: '550px'
  },
  {
    img: 'Imgs/gk25.jpg',
    title: 'Image 2',
    height: '570px'
  },
  {
    img: 'Imgs/gk20.jpg',
    title: 'Image 6',
    height: '570px'
  },
  {
    img: 'Imgs/gk21.jpg',
    title: 'Image 3',
    height: '530px'
  },
  {
    img: 'Imgs/gk18.jpg',
    title: 'Image 4',
    height: '540px'
  },

  {
    img: 'Imgs/gk23.jpg',
    title: 'Image 7',
    height: '590px'
  },
  {
    img: 'Imgs/gk22.jpg',
    title: 'Image 5',
    height: '460px'
  },
];
