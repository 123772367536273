import React from 'react'
import './Sub.css';
import { Col, Row, Form, Button } from 'react-bootstrap';
import MasonryImageList from './ImageList';

function Gallery({closeGallery,GalleryRef }) {
  return (
    <div className='Gallery-main'ref={GalleryRef}>
        <div className='Gallery'>
        <div className='close-icon-container' onClick={closeGallery} >
        </div> 
        <div className='Gallery-images'>
           
            <MasonryImageList/>

        </div>
        </div>
      
    </div>
  )
}

export default Gallery
