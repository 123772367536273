import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import Topbar from './Topbar';
import { Player } from 'video-react';
import homeVideo from '../Components/Images/home2.mp4';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DehazeIcon from '@mui/icons-material/Dehaze';
import sign from '../Components/Images/sign.png';
import About from './About';
import Gallery from './Gallery';
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
import Companies from './Companies';
import Contact from './Contact';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import Brands from './Brands';
function Home() {
  const [isTopbarVisible, setTopbarVisible] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);

  const [showGallery, setShowGallery] = useState(false);
  const playerRef = useRef(null); 
  const aboutRef = useRef(null);
  const ContactRef = useRef(null);
  const CompaniesRef = useRef(null);

  const aboutBannerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const GalleryRef = useRef(null);
  const GalleryBannerRef = useRef(null);
  const ContactsBannerRef = useRef(null);
  const CompaniesBannerRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);


  const t_homeRef = useRef(null);
  const t_aboutRef = useRef(null);
  const t_galleryRef = useRef(null);
  const t_companiesRef = useRef(null);
  const t_contactRef = useRef(null);
  const t_testimonialsRef = useRef(null);

  const scrollToSection = (section) => {
    const sectionRefs = {
      home: t_homeRef,
      about: t_aboutRef,
      gallery: t_galleryRef,
      companies: t_companiesRef,
      contact: t_contactRef,
      testimonials: t_testimonialsRef,
    };

    const ref = sectionRefs[section];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleTopbar = () => {
    setTopbarVisible(true);
  };

  const hideTopbar = () => {
    setTopbarVisible(false); 
  };

  const handleAbout = () => {
    setShowAbout((prevState) => !prevState); 
  };


  const handleContact = () => {
    setShowContact((prevState) => !prevState); 
  };

  const handleCompanies = () => {
    setShowCompanies((prevState) => !prevState); 
  };

  const handleGallery= () => {
    setShowGallery((prevState) => !prevState); 
  };

  const closeAbout = () => {
    setShowAbout(false); 
    if (aboutBannerRef.current) {
      aboutBannerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const closeCompanies = () => {
    setShowCompanies(false); 
    if (CompaniesBannerRef.current) {
      CompaniesBannerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const closeContact = () => {
    setShowContact(false); 
    if (ContactsBannerRef.current) {
      ContactsBannerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  useEffect(() => {
    if (showAbout && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showAbout]);
 
  useEffect(() => {
    if (showCompanies && CompaniesRef.current) {
      CompaniesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showCompanies]);
  useEffect(() => {
    if (showContact && ContactRef.current) {
      ContactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showContact]);


  useEffect(() => {
    if (showGallery && GalleryRef.current) {
      GalleryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showGallery]);

  const closeGallery = () => {
    setShowGallery(false); 
    if (GalleryBannerRef.current) {
      GalleryBannerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
    setIsPlaying(false); // Stop playing the previous video
  };
  

  const videoProperties = [
    { id: 1, src: 'wDfsmJJvHbQ' },
    { id: 2, src: 'XKqmjIPGFJ0' },
    { id: 3, src: 'JdpHM9uZa60' },
  ];

  // Log the active index whenever it changes
  useEffect(() => {
    console.log("Currently Playing Video: ", videoProperties[activeIndex]);
  }, [activeIndex]);


  return (
    <>
    
      {isTopbarVisible && <Topbar onClose={hideTopbar} scrollToSection={scrollToSection} />} {/* Render Topbar if visible */}
      
      <div className='main'>
        <div className='Home-banner-container'>
        <Player src={homeVideo}      controls={false}
         autoPlay
        muted
        loop />
 
          <div className='Home-heads'ref={t_homeRef}>
            <img src={sign}/>
            <h1> Dr. ANZEER KALAPPURAKAL</h1>
            <p>ENTREPRENEUR -  BRAND BUILDER - CONSULTANT</p>

          </div>
            <div className='Deahaze-icon'>
          {/* Show DehazeIcon only if Topbar is not visible */}
          {!isTopbarVisible && (
            <DehazeIcon style={{fontSize:"40px"}} className="Deahaze-icon" onClick={toggleTopbar} />
          )}
          </div>
        </div>

        <div className='About-banner-container' ref={el => {
    aboutBannerRef.current = el;
    t_aboutRef.current = el;
  }}>
          <div className='About-heads'>
            <h1>ABOUT</h1>
            <div className='icon-container' onClick={handleAbout} >
            </div>  
          </div>
        </div>
        {showAbout && <About closeAbout={closeAbout} aboutRef={aboutRef}
         />} 


        <div className='Gallery-banner-container'
        ref={el => {
          GalleryBannerRef.current = el;
          t_galleryRef.current = el;
        }}>
          <div className='Gallery-heads'>
            <h1>GALLERY</h1>
               <div className='icon-container'onClick={handleGallery}>
            </div>       </div>
        </div>
        {showGallery && <Gallery closeGallery={closeGallery} GalleryRef={GalleryRef} />} 

        <div className='Companies-banner-container' 
          ref={el => {
            CompaniesBannerRef.current = el;
            t_companiesRef.current = el;
          }}>
          <div className='Companies-heads'>
            <h1>COMPANIES</h1>
            <div className='icon-container'onClick={handleCompanies}>
            </div>  
          </div>
        </div>
        {showCompanies && <Companies closeCompanies={closeCompanies} CompaniesRef={CompaniesRef}
         />} 
      

        <div className='Contact-banner-container'
            ref={el => {
              ContactsBannerRef.current = el;
              t_contactRef.current = el;
            }}
        >
          <div className='Contact-heads'>
            <h1>CONTACT </h1>
            <div className='icon-container' onClick={handleContact}>
            </div>  
          </div>
        </div>
        {showContact && <Contact closeContact={closeContact} ContactRef={ContactRef}
         />} 

        <div className='Testimonials-banner-container' ref={t_testimonialsRef}
        >

        <Carousel activeIndex={activeIndex} onSelect={handleSelect}   interval={null}>
        {videoProperties.map((video, index) => (
        <Carousel.Item key={video.id}>
        <ReactPlayer
        url={`https://www.youtube.com/watch?v=${videoProperties[activeIndex].src}`}
        width="96%"
        height="400px"
        controls={true}
        playing={isPlaying && activeIndex === index}/>
        <Carousel.Caption>
        <h3>{video.title}</h3>
        </Carousel.Caption>
        </Carousel.Item>
        ))}
        </Carousel>

        </div>
        <Brands/>

        <div className='stay-in-touch'>
          <h1>STAY IN TOUCH</h1>

        </div>
        <div className='socials'>
        <a style={{textDecoration:"none",color:'white'}} href="https://www.facebook.com/anzeer.kalappurackal?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
       <FacebookOutlinedIcon style={{fontSize:'23px',marginBottom:"5px"}}/> FACEBOOK
  </a> &nbsp; &nbsp;
  <a style={{textDecoration:"none",color:'white'}} href="https://www.instagram.com/anzeer_kalappurackal?igsh=aXRqdmQ4NWZjYWVm" target="_blank" rel="noopener noreferrer">
   <InstagramIcon style={{fontSize:'23px',marginBottom:"5px"}}/> INSTAGRAM
  </a>
</div>
        {/* <div className='foot'>
          <p>2024 @Proudly created by sana</p>
        </div> */}

      </div>
    </>
  );
}

export default Home;
